import React from "react";
import PropTypes from "prop-types";
import { Box, Flex, Image } from "@chakra-ui/react";

import { getUserNameInitials } from "Utils/common";

/**
 * size: always in px
 */
function ProfileImageBox({ imageUrl, name, size = "32px" }) {
  return (
    <Image
      src={imageUrl}
      borderRadius="full"
      boxSize={size}
      objectFit="contain"
      fallback={
        <Box borderRadius="full" boxSize={size} fontSize={parseInt(size) * 0.5}>
          <Flex
            alignItems="center"
            justifyContent="center"
            fontWeight="bold"
            bgColor="#f3f3f3"
            color="#4a4a4a"
            height="full"
            width="full"
            borderRadius="full"
          >
            {getUserNameInitials(name)}
          </Flex>
        </Box>
      }
    />
  );
}

ProfileImageBox.propTypes = {
  imageUrl: PropTypes.string,
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ProfileImageBox;
