import { useToast } from "@chakra-ui/toast";
import { useEffect, useRef } from "react";
import axios from "Utils/axios";

const ApiCancelErrCode = {
  code: "API_CANCELED",
  message: "Programmatically canceled API request",
};

/**
 * Used to handle API calling using axios.
 * This allow you to cancel API request programmatically when component unmounts.
 */
function useApi() {
  const toast = useToast();
  const controllerRef = useRef(new AbortController());

  // To handle API's raw promise in their native way
  const apiPromise = (urlData) => {
    const { url, method, data, params } = urlData;

    return axios.request({
      data,
      params,
      signal: controllerRef.current.signal,
      method,
      url,
    });
  };

  // To handle API call with success & error response handling. This function also handles success & error notifications
  const apiHelper = (
    urlData, // type: object. urlData = { url, method, data, params }
    handleSuccess, // type: function
    showSuccessToast = false, // type: boolean
    handleError, // type: function, For custom error message, this function should return error msg else return false.
    /* (Arrow function should be like this,() => { Code... } not like this () => some code)*/
    showErrorToast = true, // type: boolean
    handleFinal, // type: function
  ) => {
    const { url, method, data, params, responseType, onUploadProgress } = urlData;

    return axios
      .request({
        data,
        params,
        signal: controllerRef.current.signal,
        method,
        url,
        responseType,
        onUploadProgress,
      })
      .then(({ data }) => {
        showSuccessToast &&
          toast({
            title: data.message || "Success",
            status: "success",
            isClosable: true,
            duration: 5000,
            position: "top-right",
          });
        handleSuccess && handleSuccess(data);
      })
      .catch((e) => {
        console.log(e);
        if (e.code == "ERR_CANCELED") {
          const { reason } = controllerRef.current.signal;

          // To skip toast of programmatically canceled API requests error msg
          if (reason?.code == ApiCancelErrCode.code) {
            console.log(ApiCancelErrCode);
            return;
          }
        }

        // In case of custom error message, handleError function should return custom error msg text
        let tempMsg = handleError && handleError(e);
        let errMsg = typeof tempMsg === "string" ? tempMsg : e.message;

        showErrorToast &&
          toast({
            title: errMsg || "Please try again!",
            status: "error",
            isClosable: true,
            duration: 5000,
            position: "top-right",
          });
      })
      .finally(() => {
        handleFinal && handleFinal();
      });
  };

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      controllerRef.current.abort(ApiCancelErrCode);
    };
  }, []);

  return { apiPromise, apiHelper };
}

export default useApi;
