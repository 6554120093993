import { extendTheme } from "@chakra-ui/react";

export const appTheme = extendTheme({
  config: {
    cssVarPrefix: "ck",
  },
  colors: { app: "#00bfff" },
  fontSizes: {
    // sm: "12px",
    // md: "14px",
  },
});
