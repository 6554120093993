import axios from "axios";
import { auth } from "../firebase";
import { AccountErrCodes } from "./ResCode";
import Qs from "qs";

const { INVALID_TOKEN_FIREBASE, ACCOUNT_INACTIVE, USER_NOT_FOUND_DB } = AccountErrCodes;

// Set config defaults when creating the instance
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

instance.interceptors.request.use(
  async (config) => {
    config["device-platform"] = "browser";

    /**
     * To handle nested query params objects and format nested params correctly
     *
     * Ref: https://stackoverflow.com/questions/54977470/send-nested-objects-in-get-method-url-search-params-in-axios#:~:text=You%20need%20to%20serialize%20your%20params
     */
    config.paramsSerializer = (params) => {
      // Qs is not included in the Axios package
      return Qs.stringify(params, {
        // Ref: https://www.npmjs.com/package/qs#:~:text=arrayFormat%3A%20%27brackets%27
        arrayFormat: "brackets",
      });
    };

    if (auth.currentUser) {
      const token = await auth.currentUser.getIdToken();
      config.headers.authorization = `Bearer ${token}`;
    } else if (sessionStorage.getItem("tempToken")) {
      const token = sessionStorage.getItem("tempToken");
      config.headers.authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.code == "ERR_CANCELED") return Promise.reject(error);

    if (!error.response || !error.response.data) {
      return Promise.reject({
        data: null,
        message: "Please try again.",
      });
    }

    // Handle logout on resCodes
    const { resCode } = error.response.data;

    if (
      resCode &&
      [INVALID_TOKEN_FIREBASE, ACCOUNT_INACTIVE, USER_NOT_FOUND_DB].includes(resCode)
    ) {
      auth.signOut();
    }

    return Promise.reject(error.response.data);
  },
);

export default instance;
