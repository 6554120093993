import moment from "moment";
import { LocalDataKeys } from "./constants";

export const truncateString = (name = "", size) => {
  return name?.length > size ? `${name.substring(0, size)}...` : name;
};

export const getUserNameInitials = (name = "") => {
  let names = name.split(" ");
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

export const getPaginatedSerialNo = (pagination, currIdx) => {
  const { page, limit } = pagination;

  const baseSerialNo = page ? (page - 1) * limit : 0;
  const serialNo = baseSerialNo + (currIdx + 1); //Assuming Idx start with 0

  return `${serialNo < 10 ? "0" : ""}${serialNo}`;
};

// string2 can be array of strings or string only
export const concatStrings = (string1, string2, separator = " ") => {
  let string = "";

  if (string1) {
    string = string1;
  }

  if (Array.isArray(string2)) {
    string2.forEach((str) => {
      if (str) {
        string += string ? `${separator + str}` : str;
      }
    });
  } else if (typeof string2 === "string") {
    string += string ? `${separator + string2}` : string2;
  }

  return string.trim();
};

export const getUserFullName = (data) => {
  return concatStrings(data.fname, data.lname) || data.vendor?.name || ""; // To show vendor name if user is of another vendor
};

export const appFormatDateTime = (dateTime, onlyDate = true) =>
  dateTime ? moment(dateTime).format(`DD MMM YYYY${onlyDate ? "" : " hh:mm A"}`) : "";

export const payloadFormatDate = (date) => (date ? moment(date).format("YYYY-MM-DD") : "");

// Payload with file need to convert in JS FormData object
export const generateFormData = (data) => {
  const convert = (form, key, data) => {
    if (data === null || data === undefined) {
      form.append(key, "");
    } else if (data instanceof File) {
      form.append(key, data);
    } else if (typeof data === "object") {
      for (const index in data) {
        let tempKey = key;

        if (index !== "$$hashKey" && !(data[index] instanceof File)) {
          /*
           In case of single element, it doesn't convert to array. So to handle that checking `$$hashKey` to distinguish array & object and setting index in case of array

           Note: Array of files should be appended in same key. Backend accept array of files in same key not in key like 'key[]' or 'key[0]'.
          */
          tempKey = `${key}[]`;
        }

        convert(form, tempKey, data[index]);
      }
    } else {
      form.append(key, data);
    }
  };

  const formData = new FormData();

  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      convert(formData, key, data[key]);
    }
  }

  return formData;
};

export const isValidUrl = (urlString) => {
  let url;

  try {
    url = new URL(urlString);
  } catch (e) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
};

export const generatePermissions = (permissionKeys, permissions) => {
  const temp = { ...permissionKeys };

  for (const key in permissionKeys) {
    if (Object.hasOwnProperty.call(permissionKeys, key)) {
      temp[key] = permissions.includes(permissionKeys[key]);
    }
  }

  return temp;
};

// In project max hour is allowed
export const generateTimeText = (minutes) => {
  if (isNaN(minutes) || minutes === null) {
    // To handle if minute is not numeric
    return "";
  }

  // TODO: Daily working minute value will be provided from backend
  const dayWorkMinute = 480; // This much of minute is considered as 1 day
  const isMoreThanTwoDay = minutes > 2 * dayWorkMinute; // If work minute is greater then 2 days then only converting work minute into days.

  let temp = "";

  const day = isMoreThanTwoDay ? parseInt(minutes / dayWorkMinute) : "";
  const hour = isMoreThanTwoDay ? parseInt((minutes % dayWorkMinute) / 60) : parseInt(minutes / 60);
  const minute = isMoreThanTwoDay
    ? Math.round((minutes % dayWorkMinute) % 60)
    : Math.round(minutes % 60);

  if (day) {
    temp = `${day}d`;
  }

  if (hour) {
    if (day) {
      temp += " ";
    }

    temp += `${hour}h`;
  }

  if (minute) {
    if (temp) {
      temp += " ";
    }

    temp += `${minute}m`;
  }

  return temp || "0m";
};

// string1: String in which existence of other string would be checked, string2: string to be checked
export const checkStringExists = (string1, string2) => {
  return string1.toLowerCase().includes(string2.toLowerCase());
};

export const storeLocal = (key, value) => key && localStorage.setItem(key, JSON.stringify(value));

export const getLocalData = (key) => key && JSON.parse(localStorage.getItem(key));

export const resetLocalData = () => {
  for (const key in LocalDataKeys) {
    if (Object.hasOwnProperty.call(LocalDataKeys, key)) {
      localStorage.removeItem(LocalDataKeys[key]);
    }
  }
};

/**
 *
 * @param {Object} data : Object that need to be sorted
 * @param {String} key : Object key that can be used to do comparison
 * @returns
 */
export const sortObjectList = (data, key) => {
  return data.sort((d1, d2) => {
    const val1 = d1[key];
    const val2 = d2[key];

    if (val1 < val2) {
      return -1;
    }
    if (val1 > val2) {
      return 1;
    }
    return 0;
  });
};

export const getHtmlContent = (htmlStr) => {
  if (!htmlStr) {
    return "";
  }

  var p = document.createElement("p");
  p.innerHTML = htmlStr;

  return p.textContent || p.innerText;
};
