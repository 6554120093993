import React from "react";
import PropTypes from "prop-types";
import { Button } from "@chakra-ui/react";

export default function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div
      className="p-8 flex flex-1 flex-col h-screen justify-center items-center border rounded-xl"
      role="alert"
    >
      <p>Something went wrong:</p>
      <pre className="m-8 text-red-600">{error.message}</pre>
      <Button size="md" colorScheme="teal" onClick={resetErrorBoundary}>
        Try again
      </Button>
    </div>
  );
}
ErrorFallback.propTypes = {
  error: PropTypes.object,
  resetErrorBoundary: PropTypes.func,
};
