// Codes for handling logout
export const AccountErrCodes = {
  INVALID_TOKEN_FIREBASE: 1070,
  USER_NOT_FOUND_DB: 1080,
  ACCOUNT_INACTIVE: 1100,
};

// Firebase login errors
export const FirebaseLoginErrCodes = {
  INVALID_PASSWORD: {
    code: "auth/wrong-password",
    message:
      "Password incorrect. In Case you have forgotten your password you can reset password or login using Google",
  },
  USER_NOT_FOUND: {
    code: "auth/user-not-found",
    message:
      "User not found. Please check your mailbox and ensure you have activated your account using welcome email.",
  },
  NO_INTERNET_CONN: { code: "auth/network-request-failed", message: "No internet connection" },
  TO_MANY_TRIES: {
    code: "auth/too-many-requests",
    message:
      "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.",
  },
  INVALID_LOGIN_CREDENTIALS: {
    code: "auth/internal-error",
    message: "You have entered an invalid email or password.",
  },
};

// To do specific task on errors
export const AppErrCodes = {
  NO_PERMISSION: 1060,
  LIMIT_EXHAUSTED: 1430,
};
