import React from "react";
import PropTypes from "prop-types";
import { Flex, Spinner, Text } from "@chakra-ui/react";

/**
 * isError : Used to show error fallback message if there is any api error
 */
function Loader({ height, spinnerSize = "lg", spinnerColor = "", isError = false, ...props }) {
  return (
    <Flex alignItems="center" justifyContent="center" h={height} {...props}>
      {isError ? (
        <Text fontWeight="bold">No data available</Text>
      ) : (
        <Spinner size={spinnerSize} {...(spinnerColor ? { color: spinnerColor } : "")} />
      )}
    </Flex>
  );
}

Loader.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  spinnerSize: PropTypes.string,
  spinnerColor: PropTypes.string,
  isError: PropTypes.bool,
};

export default Loader;
