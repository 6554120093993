import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Box } from "@chakra-ui/react";
import { AuthProvider } from "Contexts/AuthContext";
import ErrorFallback from "Components/ErrorFallback";
import Navbar from "./Components/Navbar";
import Routes from "./Routes/Routes";

function App() {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <AuthProvider>
        <Box h="100vh" position="relative">
          <Navbar />
          <Box
            position="absolute"
            top={12}
            bottom={0}
            left={0}
            right={0}
            overflow="auto"
            p={5}
            pt={4}
          >
            <Routes />
          </Box>
        </Box>
      </AuthProvider>
    </ErrorBoundary>
  );
}

export default App;
