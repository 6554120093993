import React, { Suspense, lazy } from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import Loader from "../Components/Loader";
import { useAuth } from "../Contexts/AuthContext";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";

// Public Page Components
const Login = lazy(() => import("Pages/Auth/Login"));
const ForgotPassword = lazy(() => import("Pages/Auth/ForgotPassword"));

const ResetPassword = lazy(() => import("Pages/Auth/Email/ResetPassword"));
const AdminInvite = lazy(() => import("Pages/Auth/Email/AdminInvite"));

// Private page components
const Profile = lazy(() => import("Pages/Profile/Profile"));
const Vendors = lazy(() => import("Pages/Vendors/Vendors"));
const Clients = lazy(() => import("Pages/Clients/Clients"));
const Customers = lazy(() => import("Pages/Customers/Customers"));
const VendorUsers = lazy(() => import("Pages/VendorUsers/VendorUsers"));
const Projects = lazy(() => import("Pages/Projects/Projects"));
const AllTasks = lazy(() => import("Pages/Tasks/AllTasks"));
const ProjectWiseTasks = lazy(() => import("Pages/Tasks/ProjectWiseTasks"));
const Works = lazy(() => import("Pages/Works/Works"));
const Mistakes = lazy(() => import("Pages/Mistakes/Mistakes"));
const DailyWorkReport = lazy(() => import("Pages/Reports/DailyWork"));
const WorkHourReport = lazy(() => import("Pages/Reports/WorkHour"));
const ProjectBatches = lazy(() => import("Pages/ProjectBatches/ProjectBatches"));
const MistakesReport = lazy(() => import("Pages/Reports/MistakesReport"));

function Routes() {
  const {
    defaultRoute,
    permissions: { Vd, VdUs, Cl, Cs, Wk, Ms, WrkRpt, PjBch, MsRpt },
  } = useAuth();

  return (
    <Suspense fallback={<Loader height="80vh" spinnerSize="lg" />}>
      <Switch>
        {/* Use PrivateRoute for pages which is only accessible for logged-in user */}
        {/* Use PublicRoute for pages which is only accessible for non-logged-in user */}
        {/* Use Route for pages which can be accessible for all */}
        <PublicRoute exact path="/login" title="Login" component={Login} />
        <PublicRoute
          exact
          path="/forgot-password"
          title="Forgot password"
          component={ForgotPassword}
        />
        <Route exact path="/reset-password" component={ResetPassword} />
        <Route exact path="/account-activated" component={AdminInvite} />
        <PrivateRoute exact path="/profile" isAllowed title="Profile" component={Profile} />
        <PrivateRoute
          exact
          path="/vendors"
          isAllowed={!!Vd?.viewAll}
          title="Vendors"
          component={Vendors}
        />
        <PrivateRoute
          exact
          path="/clients"
          isAllowed={!!Cl?.viewAll}
          title="Clients"
          component={Clients}
        />
        <PrivateRoute
          exact
          path="/customers"
          isAllowed={!!Cs?.viewAll}
          title="Customers"
          component={Customers}
        />
        <PrivateRoute
          exact
          path="/users"
          isAllowed={!!VdUs?.viewAll}
          title="Users"
          component={VendorUsers}
        />
        <PrivateRoute exact path="/projects" isAllowed title="Projects" component={Projects} />
        <PrivateRoute
          exact
          path="/projects/batches"
          isAllowed={!!PjBch?.viewAll}
          title="Project Batches"
          component={ProjectBatches}
        />
        <PrivateRoute exact path="/tasks" isAllowed title="Tasks" component={ProjectWiseTasks} />
        <PrivateRoute exact path="/tasks/all" isAllowed title="All Tasks" component={AllTasks} />
        <PrivateRoute
          exact
          path="/works"
          isAllowed={!!Wk?.viewAll}
          title="Works"
          component={Works}
        />
        <PrivateRoute
          exact
          path="/mistakes"
          isAllowed={!!Ms?.viewAll}
          title="Mistakes"
          component={Mistakes}
        />
        <PrivateRoute
          exact
          path="/reports/daily-work"
          isAllowed={!!WrkRpt?.daily}
          title="Daily Work Report"
          component={DailyWorkReport}
        />
        <PrivateRoute
          exact
          path="/reports/work-hour"
          isAllowed={!!WrkRpt?.minutes}
          title="Work Hour Report"
          component={WorkHourReport}
        />
        <PrivateRoute
          exact
          path="/reports/mistakes"
          isAllowed={!!MsRpt?.all}
          title="Mistakes Report"
          component={MistakesReport}
        />

        {/* Default route */}
        <PrivateRoute path="*" isAllowed component={() => <Redirect to={defaultRoute} />} />
      </Switch>
    </Suspense>
  );
}

export default Routes;
