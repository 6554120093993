import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../Contexts/AuthContext";

export default function PrivateRoute({ component: Component, isAllowed, title = "", ...rest }) {
  const { currentUser } = useAuth();
  document.title = `${title ? title + " | " : ""}${process.env.REACT_APP_WEBSITE_NAME}`;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!currentUser) {
          //not logged in, so redirect to login
          // eslint-disable-next-line react/prop-types
          return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
        }

        if (!isAllowed) {
          return <Redirect to={`/`} />; // This will redirect user to the default page/route which is defined in the Routes.js file
        }

        //authorized so return component
        return <Component {...props} />;
      }}
    ></Route>
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  isAllowed: PropTypes.bool.isRequired,
  title: PropTypes.string,
};
